<template>
  <div class="main">
    <div class="data">
      <div class="search" style="width: 100%">
        <div style="color: #fff9ff;font-size: 16px;font-weight: bold;width: 50%">
          에이전트 관리
          <el-select v-model="agent.byField" size="mini"
                     placeholder="검색조건"
                     style="width:120px;margin-left: 20px">
            <el-option label="전체" :value="null">전체</el-option>
            <el-option label="닉네임" :value="managerConst.ReferreCode.SEARCH_BYFEILD_NICKNAME">닉네임</el-option>
            <el-option label="코드" :value="managerConst.ReferreCode.SEARCH_BYFEILD_CODE">코드</el-option>
          </el-select>
          <el-input type="text" size="mini" v-model="agent.searchString" placeholder="닉네임/코드"
                    style="width: 150px;margin-left: 5px"></el-input>
          <el-button type="primary" size="mini" style="margin-left: 5px" @click="getAgentList">검색
          </el-button>
          <el-button type="info" size="mini" style="margin-left: 5px" @click="initsearch">초기화</el-button>
        </div>
        <div style="width: 50%;text-align: right">
          <el-button type="primary" size="mini"
                     @click="profitShow()">
            손이익보기
          </el-button>
          <el-button type="primary" size="mini"
                     @click="addPartner(managerConst.Parnter.PATNER_GENERALAGENT_NOID,managerConst.MODE_SAVE)">
            부본사 등록
          </el-button>
        </div>
      </div>
      <agent-recursion-comp :list="agentList" :isshowprofit="isshowprofit"></agent-recursion-comp>

    </div>

    <div class="pagePanel">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[1,5,10,20]"
          :page-size="5"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageTotal">
      </el-pagination>
    </div>
  </div>


</template>

<script>
import Vue from "vue";
import OnlineUserComp from "../../components/administrator/OnlineUserComp";
import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
import {manager} from "../../common/administrator/managerMixin";
import managerConst from "../../common/administrator/managerConst";
import {Loading} from "element-ui";
import PartnerSelectorComp from "../../components/administrator/PartnerSelectorComp";
import {delAgent, delGeneralAgent, getPartnerList} from "../../network/manager/partnerRequest";
import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
import {updateGroup} from "../../network/manager/groupRequest";
import AgentEditToast from "../../components/administrator/UserPanel/AgentEditToast";
import {getAgentList} from "../../network/manager/agentRequest";
import AgentRecursionComp from "../../components/administrator/AgentRecursionComp";

Vue.prototype.$agentEditToast = AgentEditToast

export default {
  name: "ManagerAgent",
  components: {AgentRecursionComp, PartnerSelectorComp, ManagerTopbarComp, TodayStatisticComp, OnlineUserComp},
  mixins: [manager],
  data() {
    return {
      user: {
        status: managerConst.User.STATUS_ENABLE,
      },
      agent: {},
      pageNum: 1,
      pageSize: 5,
      orderBy: 'id DESC',
      pageTotal: 0,
      agentList: [],
      editParnterId: 0,
      isshowprofit: false

    }
  },
  methods: {
    initsearch() {
      this.agent = {}
      this.pageNum = 1;
      this.pageSize = 5;
      this.getAgentList()
    },
    profitShow() {
      this.isshowprofit = !this.isshowprofit;
    },
    editPartner(id, mode) {
      this.$agentEditToast({'agentId': id, 'mode': mode})
    },
    addPartner(parentId, mode) {
      this.$agentEditToast({'agentId': parentId, 'mode': mode})
    },
    getAgentList() {
      let loadingInstance = Loading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.agent.parentId = managerConst.Parnter.PATNER_GENERALAGENT_NOID
      getAgentList(this.agent, this.pageNum, this.pageSize, this.orderBy).then(res => {
        this.pageTotal = res.data.total;
        this.agentList = res.data.data;
        console.log(this.agentList)
        loadingInstance.close();
      })
    },
    handleSizeChange(val) {
      this.pageNum = 1;
      this.pageSize = val;
      this.getAgentList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getAgentList();
    },
    setPartnerId(partnerId) {
      this.pageNum = 1
      this.user = {}
      this.user.status = managerConst.User.STATUS_ENABLE
      this.user.partnerId = partnerId
      this.getAgentList()
    },

  },
  created() {
    this.getAgentList()

    this.$bus.$on('agentEdited', (calbc) => {
      this.getAgentList()
    })
    this.$bus.$on('agentgivebackend', () => {
      console.log('agentgivebackend')
      this.getAgentList()
    })

  },
  watch: {}
}
</script>

<style scoped>
.agent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
</style>